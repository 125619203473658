<template>
  <div class="page-common-body custom-form-container" ref="wrapper">
    <custom-form
      :gid="gid"
      :good-name="goodName"
      :after-success="afterSuccess"
      @success="onSuccess"
      @fail="onFail"
    ></custom-form>
  </div>
</template>

<script>
import customForm from "./_custom-form";
export default {
  name: "product-custom-form",
  components: {
    customForm
  },
  data() {
    return {
      restHeight: 0
    };
  },
  computed: {
    gid() {
      return this.$route.query.gid;
    },
    goodName() {
      return `${this.$route.query.category}-${this.$route.query.name}`;
    }
  },
  mounted() {
    const that = this;
    that.heightFullScreen(that.$refs.wrapper, false);
  },
  methods: {
    onSuccess() {
      console.log("成功提交");
    },
    onFail(err) {
      console.log(err);
    },
    afterSuccess() {
      const that = this;
      that.$router.push({
        path: `/product/${that.gid}`
      });
    }
  }
};
</script>

<style></style>
