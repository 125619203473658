<template>
  <div class="custom-main-body">
    <div class="custom__title">
      <span>customized</span> |
      <span>简单几步，轻松定制</span>
    </div>
    <el-form :model="form" class="custom__form" ref="customForm" :rules="rules">
      <el-form-item
        v-for="(item, index) in formItem"
        :key="index"
        :label="item.label + ':'"
        class="form__item"
        :prop="item.key"
      >
        <el-input
          :type="item.type"
          v-model="form[item.key]"
          :readonly="item.readonly"
        ></el-input>
      </el-form-item>
      <el-form-item class="form__item form__bt">
        <el-button @click="onFormSubmit" :loading="requesting">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "c_custom-form",
  props: {
    gid: {
      type: String,
      default: ""
    },
    goodName: {
      type: String,
      default: ""
    },
    afterSuccess: {
      type: Function,
      default: null
    }
  },
  data() {
    const goodName = this.goodName;
    return {
      requesting: false,
      formItem: [
        {
          key: "name",
          label: "商品名称",
          type: "text",
          readonly: true
        },
        {
          key: "counts",
          label: "定制数量",
          type: "number"
        },
        {
          key: "contacts",
          label: "联系人",
          type: "text"
        },
        {
          key: "tel",
          label: "联系电话",
          type: "text"
        },
        {
          key: "mail",
          label: "联系邮箱",
          type: "text"
        }
      ],
      form: {
        name: goodName,
        counts: "",
        contacts: "",
        tel: "",
        mail: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写商品名称",
            trigger: "blur"
          }
        ],
        counts: [
          {
            required: true,
            message: "请填写定制数量",
            trigger: "blur"
          }
        ],
        contacts: [
          {
            required: true,
            message: "请填写联系人",
            trigger: "blur"
          }
        ],
        tel: [
          {
            required: true,
            message: "请填写联系电话",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    onFormSubmit() {
      const that = this;
      that.$refs.customForm.validate(valid => {
        if (valid) {
          that.requesting = true;
          that.form.gid = that.gid;
          that.$request
            .post("/api/account/custom", that.form)
            .then(res => {
              if (res.data === "SUCCESS") {
                that.$alert(
                  "您的需求已经成功发送与我们，我们会尽快与您联系，请耐心等待",
                  "发送成功",
                  {
                    confirmButtonText: "继续浏览",
                    callback: () => {
                      if (typeof that.afterSuccess === "function") {
                        that.afterSuccess();
                      }
                    }
                  }
                );

                that.$emit("success");
              }
            })
            .catch(err => {
              that.$emit("fail", err);
            })
            .finally(() => {
              that.requesting = false;
            });
          console.log("提交定制表格");
        }
      });
    }
  }
};
</script>

<style></style>
